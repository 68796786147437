export enum Features {
  driverSignUp = 'driverSignUp',
  chargbeeTrialBanner = 'chargbeeTrialBanner',
  showWelcome = 'showWelcome',

  dynamicWebTwain = 'dynamicWebTwain',
  showInbox = 'showInbox',
  showCalculateEffectiveRate = 'showCalculateEffectiveRateButton',
  createLoadFromPdf = 'createLoadFromPdf',
  showDriverGrossPayEstimate = 'showDriverGrossPayEstimate',
  teamDrivers = 'teamDrivers',
  splitLoads = 'splitLoads',
  createInvoiceOnMobile = 'createInvoiceOnMobile',
  driverSettlements = 'driverSettlements',
  ediImports = 'ediImports',
  emailImports = 'emailImports',
  customerPortal = 'customerPortal',
  liveTracking = 'liveTracking',

  rateConBanner = 'rateConBanner',
  fuelTransactions = 'fuelTransactions',
  metabase = 'metabase',
  addressLocationLink = 'addressLocationLink',
  showOrganizations = 'showOrganizations',
  stopSignature = 'stopSignature',
  qboSetCustomLoadField = 'qboSetCustomLoadField',
  maintenanceLog = 'maintenanceLog',
  automaticallyFilterDispatchUserLoads = 'automaticallyFilterDispatchUserLoads',
  otherExpenses = 'otherExpenses',
  sendQboInvoiceInternally = 'sendQboInvoiceInternally',
  flexibleTagging = 'flexibleTagging',
  downloadAppBanner = 'downloadAppBanner',
  zipAttachments = 'zipAttachments',

  otherPayInDriverPayModal = 'otherPayInDriverPayModal',
  butlerRateConModelDropdown = 'butlerRateConModelDropdown',
  dummyDeleteAccountButton = 'dummyDeleteAccountButton',
  bulkLoadImport = 'bulkLoadImport',
  enableMarkAsDelivered = 'enableMarkAsDelivered',
  systemCameraForDocumentUpload = 'systemCameraForDocumentUpload',
  showDriverProfile = 'showDriverProfile',
  factoring = 'factoring',
  reportsUnderConstruction = 'reportsUnderConstruction',
  mobileLoadInbox = 'mobileLoadInbox',
  equipmentFile = 'equipmentFile',
  notificationBanner = 'notificationBanner',
  availabilityPage = 'availabilityPage',
  downForMaintenance = 'downForMaintenance',
  warningHelper = 'warningHelper',
  androidCreateGeoLink = 'androidCreateGeoLink',
  driverTimeOff = 'driverTimeOff',
  borderConnect = 'borderConnect',
  pcMiler = 'pcMiler',
  rateTable = 'rateTable'
}
