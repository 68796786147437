import axios, { AxiosRequestConfig } from 'axios';

// Don't need to specify the hostname and port due to proxy config.
const apiUrl = '/api/';

const config: AxiosRequestConfig = {
  baseURL: apiUrl,
  withCredentials: true
};

// Don't need to specify the hostname and port due to proxy config.
const apiFactoringUrl = '/factoring/';

const factoringConfig: AxiosRequestConfig = {
  baseURL: apiFactoringUrl,
  withCredentials: true
};

const longTimeoutConfig: AxiosRequestConfig = {
  ...config,
  timeout: 300000 // 10 seconds timeout for specific routes
};

const headers = {
  'Content-type': 'application/json',
  'Access-Control-Allow-Credentials': true
};

const multipartHeaders = {
  'Content-type': 'multipart/form-data',
  'Access-Control-Allow-Credentials': true
};

config.headers = headers;
const api = axios.create(config);

config.headers = multipartHeaders;
const apiMultipart = axios.create(config);

const apiLongTimeout = axios.create(longTimeoutConfig);

factoringConfig.headers = headers;
const apiFactoring = axios.create(factoringConfig);

export const twUrl = {
  signUp: (inviteToken: string | undefined): string =>
    inviteToken ? `signup?inviteToken=${inviteToken}` : 'signup',
  inviteSignUp: (inviteToken: string | undefined): string =>
    inviteToken ? `signup-start?inviteToken=${inviteToken}` : 'signup-start',
  sendSurvey: '/surveys',
  signIn: '/login',
  signOut: '/logout',
  currentUser: '/me',
  updateAccount: '/me',
  forgotPass: 'forgot-password',
  createPass: 'create-password',
  dashboardStats: '/stats',
  locations: 'locations',
  searchLocations: 'locations/search',
  getEstimatedMilage: 'locations/estimated-milage',
  viewFile: (tripOrderAttachmentId: string): string =>
    `/trip-order-attachments/${tripOrderAttachmentId}/files-vysor`,
  viewAttachment: (emailId: string, attachmentId: string): string =>
    `/loads/forwarded-emails/${emailId}/attachments/${attachmentId}/files-vysor`, // Forwarded email files
  saveAsPDF: (id: string): string => `orders/${id}/pdf`,
  publicLoad: (token: string): string => `/guests/loads/t/${token}`,
  addGestDocument: (token: string): string => `/guests/loads/t/${token}/documents`,
  addGuestPhoto: (token: string): string => `/guests/loads/t/${token}/photos`,
  deleteGuestPhoto: (token: string, id: string): string => `/guests/loads/t/${token}/photos/${id}`,
  deleteGuestDocument: (token: string, id: string): string =>
    `/guests/loads/t/${token}/documents/${id}`,
  forwardedEmails: 'loads/forwarded-emails',
  declineEmail: (id: string): string => `loads/forwarded-emails/${id}`,
  importLoad: 'loads/forwarded-emails/import',
  quickbooksAuth: 'quickbooks/auth',
  quickbooksCallback: 'quickbooks/callback',
  getTimezones: 'timezones',
  quickbooksSignOut: 'quickbooks/auth/revoke',
  appSettings: 'settings',
  reports: 'loads/reports/data',
  users: 'tenant/users',
  inviteUser: 'tenant/send-invite-request',
  disableUser: (userId: string): string => `tenant/users/${userId}`,
  createUser: 'tenant/users',
  findUserByEmail: (email: string): string => `tenant/users/find-by-email/${email}`,
  tenantLogo: 'tenant/logo',
  editUser: (userId: string): string => `tenant/users/${userId}`,
  shareHistory: (id: string): string => `orders/${id}/share-history`,
  getLongUrl: (): string => `/long-url`,

  getEdiOrders: (): string => `edi-load-tenders/search`,
  rejectEdiOrder: (id: string): string => `edi/load-tenders/${id}/reject`,
  getInboxItemCount: 'inbox-count',
  updateUserDisplaySettings: 'user-display-settings',
  tripManifest: (id: string): string => `trips/${id}/trip-manifest`,
  v2: {
    invoicePreview: 'v2/invoices/generate-preview',
    exportInvoicesAsCSV: (): string => `v2/invoices/csv`,
    bulkUpdateInvoiceStatus: 'v2/invoices/bulk-update-status',
    getInvoiceHistory: (id: string): string => `v2/invoices/${id}/share-history`
  },
  v3: {
    extractDataFromDocument: 'v3/documents/extract-data',
    publicLoad: (token: string): string => `v3/loads/t/${token}`,
    canInvoiceBeCreated: 'orders/can-invoice-be-created',
    sendInvoice: (invoiceId: string): string => `v3/invoices/${invoiceId}/send`,
    createInvoice: (): string => `v3/invoices`,
    getInvoices: 'v3/invoices',
    invoices: 'v3/invoices/search',
    invoicesAll: 'v3/invoices/search-and-fetch-full-objects',
    idDivisionInvoices: 'v3/invoices/search-invoices-and-orders-ids-by-division-id',
    invoicePreview: 'v3/invoices/generate-preview',
    getDefaultOrderNotificationSettings: 'v3/order-status-notification-configurations',
    getOrderNotificationSettingsByOrderId: (loadId: string): string =>
      `v3/order-status-notification-configurations/?loadId=${loadId}`,
    updateOrderNotificationSettings: (id?: string): string =>
      id
        ? `v3/order-status-notification-configurations/${id}`
        : 'v3/order-status-notification-configurations',
    getInvoicePdf: (invoiceId: string): string => `v3/invoices/${invoiceId}/pdf`,
    updateInvoice: (invoiceId: string): string => `v3/invoices/${invoiceId}`,
    deleteInvoice: (invoiceId: string): string => `v3/invoices/${invoiceId}`,
    getInvoice: (invoiceId: string): string => `v3/invoices/${invoiceId}`,
    getQBOCustomFields: 'v3/invoices/qbo-custom-fields',
    getCustomerOrderById: (orderId: string): string => `v3/order-requests/${orderId}`,
    sameDivisions: 'v3/invoices/check-invoices-for-same-division',
    getOrdersForInvoice: 'orders/get-orders-for-invoice',
    importFuelTransactionsCSV: (source: string): string =>
      `v3/fuel-transactions/csv?source=${source}`,
    importBulkOrderCSV: `orders/import`,
    generateRateConfirmationPdf: (id: string): string => `orders/${id}/rate-con`,
    saveSignature: (stopId: string, loadId?: string, token?: string): string =>
      !token && loadId
        ? `v3/loads/${loadId}/stops/${stopId}/signature`
        : `v3/loads/t/${token}/stops/${stopId}/signature`,
    getSignature: (stopId: string, loadId?: string, token?: string): string =>
      `v3/loads/${loadId}/stops/${stopId}/signature`,
    generateBolPdf: (id: string): string => `orders/${id}/bol`,
    getDispatchNotes: (loadId: string, token?: string): string =>
      !token ? `v3/loads/${loadId}/dispatch-notes` : `v3/p/${token}/dispatch-notes`,
    postDispatchNote: (loadId: string, token?: string): string =>
      !token ? `v3/loads/${loadId}/dispatch-notes` : `v3/p/${token}/dispatch-notes`,
    deleteDispatchNote: (loadId: string, noteId: string, token?: string): string =>
      !token
        ? `v3/loads/${loadId}/dispatch-notes/${noteId}`
        : `v3/p/${token}/dispatch-notes/${noteId}`,
    deleteManyLoads: 'v3/loads/bulk-delete'
  },
  factoring: {
    config: 'config',
    integrations: 'integrations',
    export: 'export',
    status: 'status',
    internalFactoring: (divisionId: string): string => `settings/factoring-company/${divisionId}`
  }
};

export default api;
export { apiMultipart, apiLongTimeout, apiFactoring };
