import { TwAutocompleteOption } from '../../../components/TwAutocomplete/TwAutocomplete';
import { FilterParams } from '../savedViews/savedViews.model';
import { BaseQueryParams } from '../../Global.model';
import { CarrierResponse } from '../carriers/carriers.model';
import { DispatcherResponse } from '../dispatchers/dispatcher.model';
import { Driver } from '../driver/driver.model';
import { Equipment } from '../equipment/equipment.model';
import {
  LOAD_TYPE,
  LoadClient,
  LoadLocation,
  ShareChannel,
  ShareType,
  StopSignature
} from '../load/load.model';
import { ISearchTagsResponse } from '../loadTags/loadTags.model';
import { Order, ORDER_STATUS, OrderAttachment, OrderCommodities } from '../orders/orders.model';
import { TimeOffReason } from '../timeOff/timeOff.model';
import { SettlementInSearchTrip } from '../settlement/settlement.model';
import { PROVIDER } from '../../../providers/User/User.model';

export const TRIPS_ITEMS_PER_PAGE = 10;

export enum TRIP_STATUS {
  CANCELLED = 'CANCELLED',
  DRAFT = 'DRAFT',
  DISPATCHED = 'DISPATCHED',
  IN_TRANSIT = 'IN_TRANSIT',
  DELIVERED = 'DELIVERED'
}

export enum TripStatusColor {
  CANCELLED = '#EF4444',
  DRAFT = '#9CA3AF',
  DISPATCHED = '#A855F7',
  IN_TRANSIT = '#3A4DE9',
  DELIVERED = '#10B981'
}

export enum StopStatus {
  notStarted = 'NOT_STARTED',
  inTransit = 'IN_TRANSIT',
  arrived = 'ARRIVED',
  complete = 'COMPLETE'
}

export enum STOP_TYPE {
  PICKUP = 'PICKUP',
  DROP_OFF = 'DROP_OFF',
  TRANSFER = 'TRANSFER'
}

export enum TRIP_COVERAGE_STATUS {
  COVERED = 'COVERED',
  UNCOVERED = 'UNCOVERED'
}

export enum TRIP_DRIVER_PAY_TYPE {
  DEDUCTION = 'DEDUCTION',
  OTHER_PAY = 'OTHER_PAY',
  REIMBURSEMENT = 'REIMBURSEMENT',
  BASE = 'BASE'
}

export enum TRIP_DRIVER_PAY_UNIT {
  PERCENTAGE = 'PERCENTAGE',
  PER_HOUR = 'PER_HOUR',
  PER_MILE = 'PER_MILE',
  FLAT = 'FLAT',
  PER_LOAD = 'PER_LOAD',
  PER_STOP = 'PER_STOP',
  PER_DIEM = 'PER_DIEM'
}

export enum CALENDAR_MODE {
  DRIVER = 'DRIVER',
  TRUCK = 'TRUCK'
}

export const STOP_TYPES: TwAutocompleteOption[] = [
  {
    id: STOP_TYPE.PICKUP,
    label: 'Pickup',
    value: STOP_TYPE.PICKUP
  },
  {
    id: STOP_TYPE.DROP_OFF,
    label: 'Drop',
    value: STOP_TYPE.DROP_OFF
  },
  {
    id: STOP_TYPE.TRANSFER,
    label: 'Transfer',
    value: STOP_TYPE.TRANSFER
  }
];

export interface TripData {
  id?: string;
  order: number;
  load_id?: string;
  drivers?: TripDriverInfo[];
  truck_id?: string | null;
  trailer_id?: string | null;
  carrier_id?: string | null;
  instructions?: string | null;
  pickup_number?: string | null;
  empty_miles?: number | null;
  is_empty_miles_auto: boolean;
  estimated_mileage: number | null;
  odometer_start?: number | null;
  odometer_end?: number | null;
  status?: string;
  stops: TripStop[];
  is_loaded_miles_auto: boolean;
  loaded_mileage_source: PROVIDER;
  empty_mileage_source: PROVIDER;
}

export interface Trip {
  id: string;
  load_id?: string;
  readable_id: string | number;
  drivers: TripDriver[];
  truck_id: string | null;
  truck: Equipment | null;
  trailer_id: string | null;
  trailer: Equipment | null;
  carrier_id: string | null;
  carrier: CarrierResponse | null;
  order: number; // check if can be deleted, seems like the one used is sort_order
  instructions?: string;
  pickup_number?: string | null;
  empty_miles: number | null;
  is_empty_miles_auto: boolean;
  estimated_mileage: number | null;
  empty_mileage_source: PROVIDER;
  loaded_mileage_source: PROVIDER;
  odometer_start?: number | null;
  odometer_end?: number | null;
  status?: TRIP_STATUS;
  stops: TripStop[];
  is_loaded_miles_auto: boolean;
  sort_order: number;
  order_readable_id: string | number;
  edi_load_tender_id: string | null;
  dispatch_note_count: number | null;
  archived?: boolean;
  orders?: Order[]; // only is possible to exist when trip is LTL
  trip_commodities?: TripCommodities[];
  trip_attachments: OrderAttachment[];
  tags: ISearchTagsResponse[];
  dispatcher: DispatcherResponse | null;
  dispatcher_id: string | null;
  bol_missing?: boolean;
  has_driver_settlement: boolean;
  is_locked_by_settlement: boolean;
  other_expenses_count_by_role?: number;
  settlements: SettlementInSearchTrip[];
}

export interface TripStop {
  id?: string | null;
  trip_id?: string | null;
  segment?: any;
  location_id?: string | null;
  location?: LoadLocation | null;
  order: number; // check if can be deleted, seems like the one used is sort_order
  sort_order: number;
  trip_sort_order?: number; // TODO LTL trip response only, needed? why not sort_order?
  start_date: string;
  start_time?: string | null;
  end_date: string;
  end_time?: string | null;
  stop_type?: STOP_TYPE;
  stop_identifier?: string | null;
  notes?: string | null;
  status?: StopStatus;
  contact?: {
    contact_name?: string;
    contact_number?: string;
    contact_number_ext?: string;
    contact_email?: string;
  };
  edi_stop_identifier?: string | null;
  in_time?: string;
  out_time?: string;
  is_in_time_overriden?: boolean;
  is_out_time_overriden?: boolean;
  stop_signature_image_uploaded?: boolean;
  load_stop_signature?: StopSignature;
  coverage?: TRIP_COVERAGE_STATUS;
  order_id?: string; // Only needed for LTL enabled
  order_readable_id?: string | number; // Only needed for LTL enabled
  trip_readable_id?: string | number; // Only needed for LTL enabled
}

export interface LoadGrossPayEstimateData {
  unit?: string;
  rate?: number;
  quantity?: number;
  loaded_miles?: number;
  empty_miles?: number;
  gross_pay_estimate?: number;
  load_segment_revenue?: number;
  total_loaded_miles?: number;
  overriden_load_rate?: number;
  qbo_service_id?: string | null;
  invoice_service_id?: string | null;
}

/**
 * Trip search filter params
 * @deprecated in favor of FilterParams
 */
export interface TripSearchFilterParams {
  pickupFrom?: string; // pickup specific start date
  pickupTo?: string; // pickup specific end date
  deliveryFrom?: string; // delivery specific start date
  deliveryTo?: string; // delivery specific end date
  tripId?: number | string; // trip readable id
  orderId?: string; // order readbale id
  customerOrderNumber?: string;
  broker?: string; // broker id
  pickupLocation?: string[]; // array of location ids
  deliveryLocation?: string[]; // array of location ids
  pickupCity?: string;
  deliveryCity?: string;
  pickupState?: string;
  deliveryState?: string;
  driver?: string[]; // array of driver ids
  trailerId?: string; // array of trailer ids
  truckId?: string; // array of truck ids
  status?: TRIP_STATUS[]; // array of trip statuses,
  includeArchived?: boolean;
  prevStatus?: TRIP_STATUS[];
  carrier?: string[];
  dispatcher?: string[];
  tagIds?: string[];
  from?: string; // for calendar view
  to?: string; // for calendar view
  coverage?: TRIP_COVERAGE_STATUS;
  pickupCountry?: string;
  deliveryCountry?: string;
}

export interface TripSearchResponse {
  id: string;
  readable_id: string;
  status: TRIP_STATUS;
  start_date: string;
  start_to_date: string;
  start_from_time: string;
  start_to_time: string;
  start_in_time: string;
  start_out_time: string;
  stop_date: string;
  stop_to_date: string;
  stop_from_time: string;
  stop_to_time: string;
  stop_in_time: string;
  stop_out_time: string;
  drivers: TripSearchDriverResponse[];
  truck: Equipment;
  tags: any[];
  start_location: LoadLocation | null;
  stop_location: LoadLocation | null;
  order_status: ORDER_STATUS;
  coverage: TRIP_COVERAGE_STATUS;
  intermediate_stops_count: number;
  invoice_id: string | null;
  trailer: Equipment;
  carrier: any; // TODO: add carrier type
  carrier_id: string | null;
  trip_sort_order: number;
  customer_order: string;
  order_id: string;
  order_dispatcher: DispatcherResponse | null;
  order_readable_id: string;
  primary_client_name: string;
  primary_client_id: string;
  invoice_status: string | null;
  order_division_id: string | null;
  attachments_count: number;
  order_attachments_count: number;
  order_is_archived: boolean;
  edi_load_tender_id: string | null;
  is_locked_by_settlement: boolean;
  archived?: boolean; // TODO Ask BE to return this here
  bol_missing: boolean;
  orders?: {
    id: string;
    order: Order;
    order_id: string;
    trip_id: string | null;
  }[];
  dispatcher: DispatcherResponse | null;
  trip_commodities?: TripCommodities[];
  order_commodities?: OrderCommodities[];
  event_type?: string; // hack to push work order and time off data in calendar response
  driver_id?: string; // hack compare time off driver id
  reason?: TimeOffReason;
  settlements: SettlementInSearchTrip[];
}

export interface SearchTripsParams {
  filters?: FilterParams;
  params?: BaseQueryParams;
  config?: { calendar?: boolean; active?: boolean; calendarMode?: CALENDAR_MODE };
}
export interface TripSearchDriverResponse {
  id: string;
  trip_id: string;
  driver_id: string;
  is_primary: boolean;
  updated_at: string;
  created_at: string;
  driver: TripDriver;
}

export interface TripDriverInfo {
  id: string;
  is_primary?: boolean;
  send_driver_link?: boolean;
  driver_pay_override_data?: TripDriverOverrideData;
  driver_pay_override?: number;
  trip_driver_pay_other_payment?: TripDriverOtherPay[];
  default_load_driver_other_pay?: TripDriverOtherPay[];
}

export interface TripDriver {
  carrier_id: null | string;
  company_name: null | string;
  created_at?: string;
  created_by?: string;
  deduct_fuel_purchase_on_settlement: boolean;
  default_pay_rate_type: null | string;
  default_trailer_id: null | string;
  default_truck_id: null | string;
  deleted_at?: string | null;
  driver_pay_override?: null | number;
  driver_pay_override_data?: TripDriverOverrideData | null;
  email_address: null | string;
  first_name: null | string;
  formatted_pay_rate: null | string;
  id: string;
  initials?: null | string;
  is_primary?: boolean;
  last_name: null | string;
  name: null | string;
  orders_completed?: null | number;
  paid_ytd?: null | number;
  pay_rate: null | number;
  phone?: null | string;
  readable_pay_rate_type: null | string;
  tenant_id: string;
  trip_driver_pay_other_payment?: TripDriverOtherPay[];
  twuser_id: string;
  type: null | string;
  updated_at?: string;
  updated_by?: string;
  default_load_driver_other_pay?: TripDriverOtherPay[];
}

export interface TripDriverOverrideData {
  id?: string;
  driver_id?: string;
  trip_id?: string | null;
  description?: string;
  pay_type?: TRIP_DRIVER_PAY_TYPE;
  unit?: TRIP_DRIVER_PAY_UNIT;
  quantity?: number;
  rate?: number;
  loaded_miles?: number | null;
  total_loaded_miles?: number | null;
  empty_miles?: number | null;
  overriden_order_rate?: number | null;
  trip_revenue_percentage?: number | null;
  gross_pay_estimate?: number | null;
  is_default?: boolean;
  qbo_service_id?: string | null;
  invoice_service_id?: string | null;
  created_by?: string;
  driver_settlement_id?: string | null;
}

export interface TripDriverOtherPay {
  id?: string;
  driver_id: string;
  trip_id?: string | null;
  pay_type: TRIP_DRIVER_PAY_TYPE;
  description: string;
  unit: TRIP_DRIVER_PAY_UNIT;
  is_default: boolean;
  quantity: number;
  rate: number;
  created_by?: string;
  created_at?: Date;
  updated_at?: Date;
  updated_by?: string;
  deleted_at?: Date;
  driver_settlement_id?: string;
}

export interface BulkParams {
  searchFilters?: FilterParams;
  updateActions: {
    status?: TRIP_STATUS;
    archived?: boolean;
  };
}

export interface TripCommodities extends OrderCommodities {
  customer?: string;
  order_id?: string;
  customer_number?: string;
  order_readable_id?: string | number;
  order_commodity_id?: string;
}

export interface UpsertTrip {
  id?: string;
  orders?: string[];
  instructions?: string | null;
  trip_commodities: TripCommodities[];
  dispatcher_id?: string | null;
  order_type?: LOAD_TYPE;
  truck_id?: string | null;
  trailer_id?: string | null;
  carrier_id?: string | null;
  sort_order: number;
  drivers: TripDriverInfo[];
  is_empty_miles_auto?: boolean;
  is_loaded_miles_auto?: boolean;
  loaded_mileage_source: PROVIDER;
  empty_mileage_source: PROVIDER;
  estimated_mileage?: number | null;
  empty_miles?: number | null;
  stops: TripStop[];
  status?: TRIP_STATUS;
  archived?: boolean;
  documents?: string[];
}

/**
 * Share trip preview params
 */
export interface TripSharePreviewParams {
  driver_name?: string;
  driver_id: string;
  via_email: boolean;
  via_sms: boolean;
  message_type?: 'UPDATE' | 'UNASSIGNED' | 'SHARE';
  deleted_trip_index?: number;
  order_id?: string;
}

export interface ShareHistory {
  created_at: string;
  customer_id: string;
  customer: LoadClient;
  driver_id: string;
  driver: Driver;
  delivery_status: string;
  id: string;
  order_id: string;
  trip_id: string;
  recipient_email: string;
  sender_id: string;
  share_type: ShareType;
  channel: ShareChannel;
}
